import React from 'react'
import gologo from '../assets/images/go_logo.svg';

const HeaderGo = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={gologo} alt="Python vs Java" /></span>
        <h1>Python vs Golang</h1>
        <h2>Comparison of Python and Golang</h2>
    </header>
)

export default HeaderGo
